import React from 'react';

const LineChartIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#019386"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19 19H2.6c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.437-.437C1 18.24 1 17.96 1 17.4V1m17 5-3.919 4.183c-.148.158-.223.237-.312.278a.5.5 0 0 1-.253.044c-.098-.01-.194-.06-.387-.16L9.87 8.656c-.192-.1-.289-.15-.387-.16a.5.5 0 0 0-.253.044c-.09.04-.164.12-.312.278L5 13"
    ></path>
  </svg>
);

export default LineChartIcon;
