import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import NotFound from '../../foundation/components/not_found/NotFound';
import { RouteType, useRoutes } from '../../foundation/config/routes';
import HomePage from '../home_page/HomePage';
import {
  selectedPlanOffsetAccounts,
  selectedPlanRecords,
  selectPlanProperties,
} from '../property/redux/selectors';
import PrivateRoute from './private_route/PrivateRoute';
import PublicRoute from './public_route/PublicRoute';

const AgencyLeadAutoSignup = lazy(
  () =>
    import('../property_strategy/agency_lead_auto_signup/AgencyLeadAutoSignup'),
);

const Login = lazy(() => import('../authentication/login/Login'));
const Signup = lazy(() => import('../authentication/signup/Signup'));
const ResetPassword = lazy(
  () => import('../authentication/reset_password/ResetPassword'),
);
const AgencyLeadAccess = lazy(
  () => import('../property_strategy/agency_lead_access/AgencyLeadAccess'),
);
const AutoSignupSuccess = lazy(
  () =>
    import(
      '../property_strategy/agency_lead_auto_signup/auto_signup_success/AutoSignupSuccess'
    ),
);

const ReportAccess = lazy(
  () => import('../ai_report/report_access/ReportAccess'),
);
const DeleteAccount = lazy(
  () => import('../authentication/delete_account/DeleteAccount'),
);
const PrivacyPolicy = lazy(
  () => import('../static_content/privacy_policy/PrivacyPolicy'),
);
const TermsConditions = lazy(
  () => import('../static_content/terms_conditions/TermsConditions'),
);

function RouteManager() {
  const [isRoutesLoaded, setIsRoutesLoaded] = useState(false);

  const planProperties = useSelector(selectPlanProperties);

  const planOffsetAccounts = useSelector(selectedPlanOffsetAccounts);

  const planRecords = useSelector(selectedPlanRecords);

  const routes = useRoutes(planProperties, planOffsetAccounts, planRecords);

  const routeRenderer: any = (item: RouteType) => {
    if (!item.isSubMenu) {
      return (
        <Route
          key={item.path}
          path={item.path}
          element={
            <PrivateRoute>
              <item.component />
            </PrivateRoute>
          }
        />
      );
    } else {
      const items: any[] = [];
      if (item.routes) {
        for (const route of item.routes) {
          items.push(routeRenderer(route));
        }
      }
      return items;
    }
  };

  const privateRoutes = useMemo(() => {
    const items: any[] = [];

    for (const route of routes) {
      items.push(routeRenderer(route));
    }

    return items;
  }, [routes]);

  useEffect(() => {
    if (privateRoutes && privateRoutes.length > 0) {
      setIsRoutesLoaded(true);
    }
  }, [privateRoutes]);

  return (
    <Suspense fallback={<FullPageLoader />}>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/landing"
          element={
            <PublicRoute>
              <HomePage />
            </PublicRoute>
          }
        />
        <Route
          path="/invite"
          element={
            <PublicRoute>
              <Signup />
            </PublicRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/remi/nonclient/report"
          element={
            <PublicRoute>
              <ReportAccess />
            </PublicRoute>
          }
        />
        <Route
          path="/agency-lead"
          element={
            <PublicRoute>
              <AgencyLeadAccess />
            </PublicRoute>
          }
        />
        <Route
          path="/invite-lead"
          element={
            <PublicRoute>
              <AgencyLeadAutoSignup />
            </PublicRoute>
          }
        />
        <Route
          path="/invite-lead-success"
          element={
            <PublicRoute>
              <AutoSignupSuccess />
            </PublicRoute>
          }
        />
        <Route
          path="/delete-profile/:deleteCode"
          element={
            <PublicRoute>
              <DeleteAccount />
            </PublicRoute>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <PublicRoute>
              <PrivacyPolicy />
            </PublicRoute>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <PublicRoute>
              <TermsConditions />
            </PublicRoute>
          }
        />
        {isRoutesLoaded && (
          <>
            {privateRoutes}
            <Route path="*" element={<NotFound />} />
          </>
        )}
      </Routes>
    </Suspense>
  );
}

export default RouteManager;
