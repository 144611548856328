import {
  BarChartOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  InsertRowAboveOutlined,
  MenuOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Badge, Button, Drawer, Dropdown, Space } from 'antd';
import queryString from 'query-string';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import BriefcaseIcon from '../../../foundation/assets/svgs/BriefcaseIcon';
import Close from '../../../foundation/assets/svgs/Close';
import Logo from '../../../foundation/assets/svgs/Logo.svg';
import { useRole } from '../../../foundation/cutom_hooks/useRole';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import {
  clearStorage,
  getItemFromStorage,
  removeItemToStorage,
} from '../../../foundation/utils/storageHandler';
import { useAppDispatch } from '../../../store/hooks';
import FavoriteReportsModal from '../../ai_report/modals/FavoriteReportsModal';
import { selectFavoriteReports } from '../../ai_report/redux/selectors';
import { logout } from '../../authentication/redux/async_thunks';
import { selectUser } from '../../authentication/redux/selectors';
import { setClient } from '../../client/redux/slice';
import { selectEnterprise } from '../../enterprise/redux/selectors';
import CtaButton from '../../lead/cta_button/CtaButton';
import { setPlan } from '../../plan/redux/slice';
import ChangePassword from '../../profile/change_password/ChangePassword';
import Profile from '../../profile/Profile';
import { selectProfile } from '../../profile/redux/selectors';
import { setNavIndex } from '../../profile/redux/slice';
import RemiCtaButton from '../../suburb_scoring/remi_cta_button/RemiCtaButton';

const ProfileDropdown = ({
  homeClick,
  scoringClick,
  portfolioGrowthPlanClick,
  leadClick,
  homeNavDisplayConfig,
  resourcesClick,
}: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState(false);

  const [
    isClientView,
    isEnterpriseAdminView,
    ,
    isAgencyAdminView,
    isAgencyUserView,
    isAgencyLeadView,
  ] = useRole();

  const {
    isDesktopViewport,
    isMobileViewport,
    isTabletViewport,
    isLargeTabletViewport,
  } = useViewport();

  const location = useLocation();

  const { origin } = queryString.parse(location.search);

  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);

  const [isFavoriteModalVisible, setIsFavoriteModalVisible] = useState(false);

  const [isChangePassModalVisible, setIsChangePassModalVisible] =
    useState(false);

  const user = useSelector(selectUser);

  const profile = useSelector(selectProfile);

  const enterprise = useSelector(selectEnterprise);

  const favoriteReports = useSelector(selectFavoriteReports);

  const clearClientPlanSelections = () => {
    /**
     * Remove values for client and plan.
     */
    if (!isAgencyLeadView) {
      removeItemToStorage('client');
      removeItemToStorage('plan');
      dispatch(setClient(undefined));
      dispatch(setPlan(undefined));
    }
  };

  const handleLogout = async () => {
    const getUser = getItemFromStorage('user');
    // @ts-ignore
    const userStorage = JSON.parse(getUser);
    const fixUser = user || userStorage;

    try {
      if (fixUser) {
        const data: any = {
          email: profile?.email,
          userId: fixUser?.user_id,
        };

        await dispatch(
          logout({
            token: fixUser?.token,
            data,
          }),
        ).unwrap();
        clearStorage();

        window.location.href = '/';
      }
    } catch (error) {
      console.log(error);
    }
  };

  const InfoView = useMemo(() => {
    return (
      <div className="c-app-layout__profile-dd-info-wrapper">
        <div className="c-app-layout__profile-dd-info-avatar">
          <Badge dot color="#12B76A">
            <Avatar
              src={profile?.picture}
              icon={<UserOutlined />}
              size={'large'}
            />
          </Badge>
        </div>
        <div className="c-app-layout__profile-dd-info-text-wrapper">
          <div className="c-app-layout__profile-dd-info-text-name">
            {profile?.name}
          </div>
          <div className="c-app-layout__profile-dd-info-text-email">
            {profile?.email}
          </div>
        </div>
      </div>
    );
  }, [profile]);

  const handleViewProfileClick = () => {
    setIsProfileModalVisible(true);
  };

  const handleSupportClick = () => {
    clearClientPlanSelections();
    dispatch(setNavIndex(-1));
    navigate('/contact-us');
  };

  const handleEnterpriseSettingsClick = () => {
    dispatch(setNavIndex(-1));
    navigate('/enterprise-settings');
  };

  const handleAgencySettingsClick = () => {
    if (user) {
      clearClientPlanSelections();
      dispatch(setNavIndex(-1));

      // Show client selection page.
      navigate(`/agency/${user.agency_id}`);
    }
  };

  const handleViewFavoriteReportsClick = () => {
    setIsFavoriteModalVisible(true);
  };

  const menu = useMemo(() => {
    const menuItems: any = [];

    if (!isMobileViewport) {
      menuItems.push({
        key: '1',
        label: InfoView,
      });
    }

    menuItems.push({
      key: '2',
      onClick: handleViewProfileClick,
      label: 'View profile',
    });

    if (isEnterpriseAdminView && enterprise) {
      menuItems.push({
        key: '2.2',
        onClick: handleEnterpriseSettingsClick,
        label: 'Enterprise settings',
      });
    }

    if (isAgencyAdminView) {
      menuItems.push({
        key: '2.4',
        onClick: handleAgencySettingsClick,
        label: 'Agency settings',
      });
    }

    if ((isAgencyAdminView || isAgencyUserView) && favoriteReports?.length) {
      menuItems.push({
        key: '2.6',
        onClick: handleViewFavoriteReportsClick,
        label: 'Favorite suburb reports',
      });
    }

    menuItems.push({
      key: '3',
      onClick: handleSupportClick,
      label: 'Support',
    });

    if (!isMobileViewport) {
      menuItems.push({
        key: '4',
        onClick: handleLogout,
        label: 'Log out',
      });
    }

    return menuItems;
  }, [
    profile,
    user,
    enterprise,
    isEnterpriseAdminView,
    favoriteReports,
    isMobileViewport,
  ]);

  const mobileMenu = useMemo(() => {
    let menuItems: any[] = [];

    if (isAgencyLeadView) {
      menuItems = [
        {
          key: '1',
          onClick: leadClick,
          label: 'Home',
          icon: <HomeOutlined />,
        },
        {
          key: '2',
          onClick: scoringClick,
          label: 'REMi',
          icon: <EnvironmentOutlined />,
        },
        {
          key: '3',
          onClick: portfolioGrowthPlanClick,
          label: 'Portfolio Growth Plan',
          icon: <BriefcaseIcon />,
        },
        // {
        //   key: '4',
        //   onClick: resourcesClick,
        //   label: 'Resources',
        //   icon: <CubeIcon color="#019386" />,
        // },
      ];
    } else if (isClientView) {
      menuItems = [
        {
          key: '1',
          onClick: homeClick,
          label: 'Current Plans & Shared Reports',
          icon: <HomeOutlined />,
        },
        {
          key: '2',
          onClick: scoringClick,
          label: 'REMi',
          icon: <EnvironmentOutlined />,
        },
        // {
        //   key: '3',
        //   onClick: resourcesClick,
        //   label: 'Resources',
        //   icon: <CubeIcon color="#019386" />,
        // },
      ];
    } else if (isEnterpriseAdminView) {
      menuItems = [
        {
          key: '1',
          onClick: homeClick,
          label: 'Home',
          icon: <HomeOutlined />,
        },
      ];
    } else {
      menuItems = [
        {
          key: '1',
          onClick: homeClick,
          label: 'Search Clients',
          icon: <HomeOutlined />,
        },
        {
          key: '2',
          onClick: scoringClick,
          label: 'REMi',
          icon: <EnvironmentOutlined />,
        },
        // {
        //   key: '3',
        //   onClick: resourcesClick,
        //   label: 'Resources',
        //   icon: <CubeIcon color="#019386" />,
        // },
      ];
    }

    return menuItems;
  }, [
    profile,
    user,
    enterprise,
    isAgencyAdminView,
    isAgencyLeadView,
    isAgencyUserView,
    favoriteReports,
    isClientView,
  ]);

  const handleProfileModalClose = () => {
    setIsProfileModalVisible(false);
  };

  const handleChangePassModalClose = () => {
    setIsChangePassModalVisible(false);
  };

  const handleFavoriteModalClose = () => {
    setIsFavoriteModalVisible(false);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <div style={{ display: 'flex' }}>
      {isMobileViewport && (
        <Drawer
          title={
            <div className="c-app-layout__header-logo">
              <Logo />
            </div>
          }
          closable={false}
          placement={'left'}
          width={'100%'}
          onClose={handleDrawerClose}
          open={openDrawer}
          maskClosable={false}
          extra={
            <Space>
              <span
                onClick={handleDrawerClose}
                className="c-app-layout__header-mobile-drawer-close-btn"
              >
                <Close color="#667085" />
              </span>
            </Space>
          }
          styles={{
            body: {
              padding: 0,
            },
          }}
        >
          <div className="c-app-layout__header-mobile-drawer-menu">
            <div>
              <div className="c-app-layout__header-mobile-drawer-menu-item c-app-layout__header-mobile-drawer-menu-item--info">
                {InfoView}
              </div>
              {mobileMenu &&
                mobileMenu.length > 0 &&
                mobileMenu.map((item) => (
                  <div
                    key={item.key}
                    onClick={() => {
                      handleDrawerClose();
                      item.onClick();
                    }}
                    className="c-app-layout__header-mobile-drawer-menu-item"
                  >
                    <div className="c-app-layout__header-mobile-drawer-menu-item-icon">
                      {item.icon}
                    </div>
                    <div className="c-app-layout__header-mobile-drawer-menu-item-label">
                      {item.label}
                    </div>
                  </div>
                ))}

              {menu &&
                menu.map((item) => {
                  return (
                    <div
                      key={`menu ${item}`}
                      className="c-app-layout__header-mobile-drawer-menu-item"
                      onClick={() => {
                        handleDrawerClose();
                        item.onClick();
                      }}
                    >
                      <div className="c-app-layout__header-mobile-drawer-menu-item-label">
                        {item.label}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="c-app-layout__header-mobile-drawer-bottom-menu-item-wrapper">
              <Button
                type="primary"
                className="c-app-layout__header-mobile-drawer-bottom-menu-item"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </div>
          </div>
        </Drawer>
      )}
      {isChangePassModalVisible && (
        <ChangePassword closeModal={handleChangePassModalClose} />
      )}
      {isProfileModalVisible && (
        <Profile
          closeModal={handleProfileModalClose}
          showChangePasswordModal={() => setIsChangePassModalVisible(true)}
          handleLogout={handleLogout}
        />
      )}
      {isFavoriteModalVisible && (
        <FavoriteReportsModal closeModal={handleFavoriteModalClose} />
      )}
      {!isMobileViewport && <RemiCtaButton isHeader />}
      {!isMobileViewport && (!origin || origin !== 'myprop') && (
        <CtaButton isHeader />
      )}
      {(((isAgencyLeadView || isAgencyAdminView || isAgencyUserView) &&
        (isTabletViewport || isLargeTabletViewport)) ||
        (!isAgencyLeadView &&
          !isAgencyUserView &&
          !isAgencyAdminView &&
          !isDesktopViewport)) && (
        <div className="c-app-layout__header-nav-small">
          {!isAgencyLeadView && !isMobileViewport && (
            <button onClick={homeClick}>
              <Avatar
                className="h-color--dark"
                icon={
                  homeNavDisplayConfig
                    ? homeNavDisplayConfig[user?.role_id ?? '']?.displayIcon
                    : null
                }
              />
            </button>
          )}
          {isAgencyLeadView && (
            <button onClick={leadClick}>
              <Avatar className="h-color--dark" icon={<HomeOutlined />} />
            </button>
          )}
          {/* {(isAgencyAdminView ||
            isAgencyUserView ||
            isAgencyLeadView ||
            isClientView) &&
            !isMobileViewport && (
              <button onClick={resourcesClick}>
                <Avatar
                  className="h-color--dark"
                  icon={<CubeIcon color="white" />}
                />
              </button>
            )} */}
          {(isAgencyAdminView || isAgencyUserView || isAgencyLeadView) && (
            <button onClick={scoringClick}>
              <Avatar
                className="h-color--dark"
                icon={<InsertRowAboveOutlined />}
              />
            </button>
          )}
          {isAgencyLeadView && (
            <button onClick={portfolioGrowthPlanClick}>
              <Avatar className="h-color--dark" icon={<BarChartOutlined />} />
            </button>
          )}
        </div>
      )}

      {(isAgencyAdminView ||
        isAgencyUserView ||
        isAgencyLeadView ||
        isClientView) &&
        (isTabletViewport || isLargeTabletViewport) && (
          <div className="c-app-layout__header-nav-small">
            <button onClick={scoringClick}>
              <Avatar
                className="h-color--dark"
                icon={<EnvironmentOutlined />}
              />
            </button>
          </div>
        )}

      {isMobileViewport && (
        <div
          className="c-app-layout__header-user"
          style={{ marginRight: '5px' }}
          onClick={() => setOpenDrawer(true)}
        >
          <div className="c-app-layout__header-user-avatar">
            <Avatar className="h-color--dark" icon={<MenuOutlined />} />
          </div>
        </div>
      )}
      {!isMobileViewport && (
        <Dropdown
          menu={{ items: menu }}
          className="c-app-layout__header-user-dd"
          overlayClassName="c-app-layout__header-user-dd"
        >
          <div className="c-app-layout__header-user">
            {!isTabletViewport && !isMobileViewport && (
              <div className="c-app-layout__header-user-name">
                {profile?.name}
              </div>
            )}
            <div className="c-app-layout__header-user-avatar">
              <Avatar
                className="h-color--dark"
                src={profile?.picture}
                icon={<UserOutlined />}
              />
            </div>
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export default ProfileDropdown;
