import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Layout } from 'antd';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';

import ContentImage from '../../foundation/assets/images/Content.png';
import Dashboard from '../../foundation/assets/images/dashboard.png';
import Deloitte2022 from '../../foundation/assets/images/Deloitte2022.png';
import Deloitte2023 from '../../foundation/assets/images/Deloitte2023.png';
import Deloitte2024 from '../../foundation/assets/images/Deloitte2024.png';
import HomePage1 from '../../foundation/assets/images/HomePage1.png';
import Laptop from '../../foundation/assets/images/laptop.png';
import MacBookPro16 from '../../foundation/assets/images/MacBookPro16.png';
import MostInnovative from '../../foundation/assets/images/MostInnovative.png';
import ProptechAward from '../../foundation/assets/images/ProptechAward.png';
import CoinsHandIcon from '../../foundation/assets/svgs/CoinsHandIcon';
import EyeIcon from '../../foundation/assets/svgs/EyeIcon';
import LineChartIcon from '../../foundation/assets/svgs/LineChartIcon';
import Logo from '../../foundation/assets/svgs/Logo.svg';
import UserIcon from '../../foundation/assets/svgs/UserIcon1';
import Footer from '../lead/footer/Footer';

const { Header, Content } = Layout;

const HomePage = () => {
  const navigate = useNavigate();

  const handleSignUpClick = () => {
    navigate('/invite');
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  const GetFreeAccessBtn = useMemo(() => {
    return (
      <Button
        type="primary"
        className="c-home-page__get-free-btn"
        onClick={handleSignUpClick}
      >
        Get Free Access
      </Button>
    );
  }, []);

  return (
    <Layout hasSider={false} className="c-home-page">
      <Header className="c-home-page__header">
        <div className="c-home-page__header-logo">
          <Logo />
        </div>
        <div className="c-home-page__header-actions">
          <Button
            onClick={handleLoginClick}
            className="c-home-page__header-action-btn c-home-page__header-action-btn--login"
          >
            Log in
          </Button>
          <Button
            type="primary"
            onClick={handleSignUpClick}
            className="c-home-page__header-action-btn c-home-page__header-action-btn--signup"
          >
            Sign up
          </Button>
        </div>
      </Header>
      <Content>
        <div className="c-home-page__section c-home-page__section--first">
          <div className="c-home-page__section-content c-home-page__section-content--first">
            <div className="c-home-page__main-content-sml-heading">
              Property Pathfinder
            </div>
            <div className="c-home-page__main-content-main-heading">
              The future of property investing starts here
            </div>
            <div className="c-home-page__section-sub-heading">
              Smarter decisions. Less guesswork.
            </div>
            <div className="c-home-page__section-description">
              <p>
                Property Pathfinder empowers investors with AI-driven insights,
                real-time market intelligence and strategies to help make
                smarter, more confident decisions. All for free.
              </p>
              <p>
                {`Backed by a $5 million investment in developing cutting-edge
                technology, Property Pathfinder combines advanced data insights,
                artificial intelligence and personalised strategies to take the
                guesswork out of property investing. Whether you're planning
                your first investment or optimising a multi-property portfolio,
                Property Pathfinder gives you an edge.`}
              </p>
            </div>

            {GetFreeAccessBtn}
          </div>
          <div className="c-home-page__section-img c-home-page__section-img--first">
            <img src={ContentImage} alt="Hero section" />
          </div>

          <div className="c-home-page__section-img-mobile c-home-page__section-img-mobile--half">
            <img src={Dashboard} alt="Hero section" />
          </div>
        </div>
        <div
          className="c-home-page__section c-home-page__section--small"
          style={{ backgroundColor: '#fff' }}
        >
          <div className="c-home-page__section-heading">
            Invest with confidence
          </div>
          <div className="c-home-page__confidence-section-content">
            <div className="c-home-page__confidence-section-item">
              <div className="c-home-page__confidence-section-item-icon">
                <UserIcon />
              </div>
              <div className="c-home-page__confidence-section-item-content">
                Personalised property portfolio plans aligned to your goals
              </div>
            </div>
            <div className="c-home-page__confidence-section-item">
              <div className="c-home-page__confidence-section-item-icon">
                <EyeIcon />
              </div>
              <div className="c-home-page__confidence-section-item-content">
                Portfolio monitoring tools to track property performance in real
                time
              </div>
            </div>
            <div className="c-home-page__confidence-section-item">
              <div className="c-home-page__confidence-section-item-icon">
                <LineChartIcon />
              </div>
              <div className="c-home-page__confidence-section-item-content">
                Suburb scoring to pinpoint the top suburbs nationwide that align
                with your strategy
              </div>
            </div>
            <div className="c-home-page__confidence-section-item">
              <div className="c-home-page__confidence-section-item-icon">
                <CoinsHandIcon />
              </div>
              <div className="c-home-page__confidence-section-item-content">
                Price and rent forecasting models to identify high-growth and
                cash flow suburbs
              </div>
            </div>
          </div>
        </div>

        <div className="c-home-page__section c-home-page__section--centralized">
          <div className="c-home-page__section-heading">How it works</div>
          <div className="c-home-page__section-description c-home-page__section-description--sub-heading">
            When you sign up for free to Property Pathfinder, you gain access to
            two key features to help you invest with confidence.
          </div>

          <div className="c-home-page__section c-home-page__section--no-padding">
            <div className="c-home-page__section-content ">
              <div className="c-home-page__section-heading">
                {`Portfolio Growth Plan (PGP)`}
              </div>
              <p className="c-home-page__section-description">
                {`A proprietary tool powered by AI and neural networks, PGP helps
                you:`}
              </p>

              <div className="c-home-page__how-it-works-points-container">
                <div className="c-home-page__how-it-works-point">
                  <span>
                    <CheckCircleOutlined />
                  </span>
                  <span className="c-home-page__section-description">
                    {`Create personalised portfolio plans tailored to your
                  individual goals`}
                  </span>
                </div>

                <div className="c-home-page__how-it-works-point">
                  <span>
                    <CheckCircleOutlined />
                  </span>
                  <span className="c-home-page__section-description">
                    {`Track your progress to stay on course and achieve your goals`}
                  </span>
                </div>

                <div className="c-home-page__how-it-works-point">
                  <span>
                    <CheckCircleOutlined />
                  </span>
                  <span className="c-home-page__section-description">
                    {`Monitor your portfolio's performance with live valuation estimates`}
                  </span>
                </div>
              </div>

              {GetFreeAccessBtn}
            </div>
            <div className="c-home-page__section-img">
              <img src={HomePage1} alt="Hero section" />
            </div>
            <div className="c-home-page__section-img-mobile">
              <img src={Laptop} alt="Hero section" />
            </div>
          </div>
        </div>

        <Divider />

        <div className="c-home-page__section">
          <div className="c-home-page__section-img">
            <img src={MacBookPro16} alt="Hero section" />
          </div>

          <div className="c-home-page__section-content ">
            <div className="c-home-page__section-heading c-home-page__section-heading--col">
              <span style={{ display: 'inline-block' }}>REMi</span>

              <span
                className="c-home-page__section-sub-heading"
                style={{ display: 'inline-block', marginBottom: 0 }}
              >
                (Real Estate Market Intelligence)
              </span>
            </div>
            <div className="c-home-page__section-description">
              Powered by over 2.6 billion data points, REMi applies advanced
              machine learning to suburb data, delivering high-quality
              investment insights. With REMi, you get:
            </div>

            <div className="c-home-page__how-it-works-points-container">
              <div className="c-home-page__how-it-works-point">
                <span>
                  <CheckCircleOutlined />
                </span>
                <span className="c-home-page__section-description">
                  Nationwide coverage of suburb growth, cashflow, affordability,
                  and socio-economic scores
                </span>
              </div>

              <div className="c-home-page__how-it-works-point">
                <span>
                  <CheckCircleOutlined />
                </span>
                <span className="c-home-page__section-description">
                  Suburb reports for targeted high-growth or cash flow location
                </span>
              </div>

              <div className="c-home-page__how-it-works-point">
                <span>
                  <CheckCircleOutlined />
                </span>
                <span className="c-home-page__section-description">
                  Forecasting models predicting suburb property prices and rents
                  up to 15 months in advance, with error rates of just 3.56% for
                  prices and 3.25% for rents.
                </span>
              </div>
            </div>

            {GetFreeAccessBtn}
          </div>
          <div className="c-home-page__section-img-mobile">
            <img src={MacBookPro16} alt="Hero section" />
          </div>
        </div>

        <div
          className="c-home-page__section c-home-page__section--centralized c-home-page__section--small "
          style={{ backgroundColor: '#fff' }}
        >
          <div className="c-home-page__section-heading">
            Award-winning innovation
          </div>
          <div className="c-home-page__award-section-container">
            <div className="c-home-page__award-section-content">
              {[
                MostInnovative,
                ProptechAward,
                Deloitte2022,
                Deloitte2023,
                Deloitte2024,
              ].map((award, index) => (
                <div key={index} className="c-home-page__award-section-item">
                  <div className="c-home-page__award-section-item-img">
                    <img src={award} alt="Award" />
                  </div>
                  <div className="c-home-page__award-section-item-content">
                    {award === MostInnovative &&
                      '#2 in the Property, Construction & Transport Industry 2023'}
                    {award === ProptechAward &&
                      'Finalist in the Ownership, Affordability & Finance Category 2023'}
                    {award === Deloitte2022 &&
                      '#14 Fastest Growing Tech Company in Australia 2022'}
                    {award === Deloitte2023 &&
                      '#20 Fastest Growing Tech Company in Australia 2023'}
                    {award === Deloitte2024 &&
                      '#43 Fastest Growing Tech Company in Australia 2024'}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Content>
      <Footer hasLogo={false} />
    </Layout>
  );
};

export default HomePage;
