import React from 'react';

const CoinsHandIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    fill="none"
    viewBox="0 0 22 22"
  >
    <path
      stroke="#019386"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12.53 7.352a3.5 3.5 0 1 1 .941-4.704M5 19.088h2.61q.512 0 1.009.12l2.758.67c.598.147 1.222.16 1.826.043l3.05-.593a4.2 4.2 0 0 0 2.127-1.107l2.158-2.1a1.503 1.503 0 0 0 0-2.168 1.61 1.61 0 0 0-2.06-.143l-2.515 1.835c-.36.263-.799.405-1.25.405h-2.427 1.545c.871 0 1.577-.687 1.577-1.534v-.307c0-.703-.492-1.317-1.194-1.487l-2.385-.58A5 5 0 0 0 10.643 12c-.965 0-2.711.799-2.711.799L5 14.025M19 5.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0M1 13.6v5.8c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C1.76 21 2.04 21 2.6 21h.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437C5 20.24 5 19.96 5 19.4v-5.8c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437C4.24 12 3.96 12 3.4 12h-.8c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C1 12.76 1 13.04 1 13.6"
    ></path>
  </svg>
);

export default CoinsHandIcon;
