import React from 'react';

const UserIcon1 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#019386"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M10 13c-3.17 0-5.99 1.53-7.784 3.906-.386.511-.58.767-.573 1.112.005.267.172.604.382.769.272.213.649.213 1.402.213h13.146c.753 0 1.13 0 1.402-.213.21-.165.377-.502.382-.769.006-.345-.187-.6-.573-1.112C15.989 14.531 13.17 13 10 13M10 10a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9"
    ></path>
  </svg>
);

export default UserIcon1;
