import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import {
  clearStorage,
  getItemFromStorage,
} from '../../foundation/utils/storageHandler';
import { useAppDispatch } from '../../store/hooks';
import { refreshToken } from '../authentication/redux/async_thunks';
import {
  selectClientIp,
  selectRoleOptions,
  selectUser,
} from '../authentication/redux/selectors';
import { setCanProceed, setUser } from '../authentication/redux/slice';
import { User } from '../authentication/redux/types';
import { setClient } from '../client/redux/slice';
import { setLastVisitedPlanId, setPlan } from '../plan/redux/slice';
import { fetchProfile } from '../profile/redux/async_thunks';
import { selectProfile } from '../profile/redux/selectors';
import { setNavIndex } from '../profile/redux/slice';
import {
  fetchFormValues,
  fetchPlanProperties,
} from '../property/redux/async_thunks';

const publicRoutes = [
  '/login',
  '/landing',
  '/terms-and-conditions',
  '/privacy-policy',
  '/invite',
  '/reset-password',
  '/remi/nonclient/report',
  '/agency-lead',
  '/invite-lead',
  '/invite-lead-success',
  '/delete-profile/:deleteCode',
];

function Startup() {
  const roleIds = useSelector(selectRoleOptions);

  const [isLoading, setIsLoading] = useState(true);

  const clientIp = useSelector(selectClientIp);

  const user = useSelector(selectUser);

  const profile = useSelector(selectProfile);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const getAPIParams = () => {
    type refreshTokenParamsType = {
      params: { userId: string; token: string };
      storedUser: User;
    };

    let apiParams: refreshTokenParamsType | undefined = undefined;

    const currentUserData = getItemFromStorage('user');

    if (currentUserData) {
      const data = JSON.parse(currentUserData);

      apiParams = {
        params: {
          userId: data.user_id,
          token: data.token,
        },
        storedUser: data,
      };
    }

    return apiParams;
  };

  const createNetworkCallPromisesArray = (
    updatedUserData: User,
    currentPlanData: string,
  ) => {
    const $promises: any[] = [];

    const isSuperAdmin =
      updatedUserData && updatedUserData.role_id === roleIds?.superAdmin;

    const isEnterpriseAdmin =
      updatedUserData && updatedUserData.role_id === roleIds?.enterpriseAdmin;

    const isAgencyLead =
      updatedUserData && updatedUserData.role_id === roleIds?.enterpriseAdmin;

    if (currentPlanData && !isSuperAdmin && !isEnterpriseAdmin) {
      const plan = JSON.parse(currentPlanData);
      dispatch(setPlan(plan));

      if (!isAgencyLead && updatedUserData && updatedUserData.token) {
        $promises.push(
          dispatch(
            fetchPlanProperties({
              token: updatedUserData.token,
              planId: plan.planId,
            }),
            // @ts-ignore
          ).unwrap(),
        );
      }
    }

    if (checkValuesEpRolePermission(updatedUserData).isAllowed) {
      $promises.push(
        dispatch(
          fetchFormValues({
            token: updatedUserData.token,
          }),
          // @ts-ignore
        ).unwrap(),
      );
    }

    if (updatedUserData && updatedUserData.token) {
      $promises.push(
        dispatch(
          fetchProfile({
            token: updatedUserData.token,
            email: updatedUserData.email,
            agencyId: updatedUserData.agency_id,
          }),
        ).unwrap(),
      );
    }

    return $promises;
  };

  const checkRedirect = () => {
    const reportCode = sessionStorage.getItem('report_code');

    if (reportCode) {
      navigate(`/remi/shared-report/${reportCode}`);
    }
  };

  const checkValuesEpRolePermission = (user: User) => {
    return {
      isAllowed:
        user?.role_id !== roleIds?.superAdmin &&
        user?.role_id !== roleIds?.enterpriseAdmin &&
        user?.role_id !== roleIds?.nonClient,
    };
  };

  const setAuthData = async (updatedUserData: any) => {
    try {
      /**
       * Fetch the values from browser's storage.
       */

      const currentClientData = getItemFromStorage('client');

      const currentPlanData = getItemFromStorage('plan');

      const isSuperAdmin =
        updatedUserData && updatedUserData.role_id === roleIds?.superAdmin;
      const isEnterpriseAdmin =
        updatedUserData && updatedUserData.role_id === roleIds?.enterpriseAdmin;
      const isClient =
        updatedUserData && updatedUserData.role_id === roleIds?.client;
      const isAgencyLead =
        updatedUserData && updatedUserData.role_id === roleIds?.agencyLead;

      /**
       * Set the client and plan data in redux based on their avaialabilities
       * only for the non-admin users. We can't use useRoles here because that's not available
       * at the time this function is invoked.
       */
      if (currentClientData && !isEnterpriseAdmin && !isSuperAdmin) {
        dispatch(setClient(JSON.parse(currentClientData)));
      }

      const path = location.pathname;

      /**
       * Handle browser routing based on the avaialble values.
       */
      if (isAgencyLead) {
        // If agency lead
        const campaign = sessionStorage.getItem('lead_signup_success_campaign');

        switch (campaign) {
          case '001':
            dispatch(setNavIndex(1));
            navigate('/remi');
            break;
          case '002':
            dispatch(setNavIndex(2));
            navigate('/portfolio-growth-plan');
            break;
          default:
            dispatch(setNavIndex(0));
            navigate('/lead-landing');
            break;
        }
      } else if (!isEnterpriseAdmin && !isSuperAdmin) {
        // If admin user
        navigate(path);
      } else if (isClient) {
        // If client account
        if (currentClientData && currentPlanData) {
          if (path === '/') {
            navigate('/dashboard');
          } else {
            navigate(path);
          }
        } else if (currentClientData && !currentPlanData) {
          navigate('/plan');
        } else {
          navigate(`/landing`);
        }
      } else {
        // If normal user
        if (currentClientData && currentPlanData) {
          if (path === '/') {
            navigate('/dashboard');
          } else {
            navigate(path);
          }
        } else if (!currentPlanData && currentClientData) {
          navigate('/plan');
        } else {
          navigate('/');
        }
      }

      dispatch(setCanProceed(true));
      setIsLoading(false);
    } catch (error) {
      clearStorage();
      navigate('/landing');
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      if (user) {
        checkRedirect();

        const currentPlanData = getItemFromStorage('plan');

        const $promises = createNetworkCallPromisesArray(
          user,
          currentPlanData ?? '',
        );

        await Promise.all($promises);
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const initiateAuth = async () => {
    try {
      if (!user) {
        const apiParams = getAPIParams();

        /**
         * This lead_login object if present indicates that a lead user has logged in
         * and a page reload was initiated
         */
        const leadLoginKey = 'lead_login';
        const storedLeadLogin = sessionStorage.getItem(leadLoginKey);

        /**
         * Call the refreshToken API to get the updated values.
         */
        let updatedUserData: any = undefined;

        if (apiParams) {
          if (!storedLeadLogin) {
            updatedUserData = await dispatch(
              refreshToken({
                data: { userId: apiParams?.params?.userId },
                token: apiParams?.params?.token,
              }),
              // @ts-ignore
            ).unwrap();
          } else {
            const parsed = JSON.parse(storedLeadLogin);

            const lastVisitedPlanId =
              parsed && parsed.lastVisitedPlanId !== 'n/a'
                ? parsed.lastVisitedPlanId
                : null;

            const client = getItemFromStorage('client');

            if (lastVisitedPlanId !== undefined && client) {
              updatedUserData = apiParams?.storedUser;

              dispatch(setUser(updatedUserData));
              dispatch(setClient(JSON.parse(client)));
              dispatch(setLastVisitedPlanId(lastVisitedPlanId));
            }

            sessionStorage.removeItem(leadLoginKey);
          }
        }

        if (updatedUserData?.token) {
          setAuthData(updatedUserData);
        } else {
          const isPublicRoute = publicRoutes.some((route) => {
            const regex = new RegExp(`^${route.replace(/:\w+/g, '\\w+')}/*$`);

            return regex.test(location.pathname);
          });

          if (!isPublicRoute) {
            clearStorage();
            navigate('/landing');
          }

          dispatch(setCanProceed(true));
        }
      }
    } catch (error) {
      clearStorage();
      navigate('/landing');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (clientIp) {
      initiateAuth();
    }
  }, [clientIp]);

  useEffect(() => {
    if (roleIds && user && !profile) {
      fetchData();
    }
  }, [user]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  return null;
}

export default Startup;
