import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { getItemFromStorage } from '../../../foundation/utils/storageHandler';

// Typescript Props
type Props = {
  children: any;
};

const PrivateRoute = ({ children }: Props): JSX.Element => {
  const location = useLocation();

  /*
   * A workaround that checks a target route, store a parameter,
   * then after authentication, use that parameter to do a redirect.
   * Refactor later & make it configurable/reusable across many routes.
   */
  const checkRedirect = () => {
    const targetRoute = '/remi/shared-report';
    const storeKey = 'report_code';

    sessionStorage.removeItem(storeKey);

    if (location.pathname.startsWith(targetRoute)) {
      const pathSegments = location.pathname.split('/');
      const reportCodeParam = pathSegments[pathSegments.length - 1];
      sessionStorage.setItem(storeKey, reportCodeParam);
    }
  };

  if (!getItemFromStorage('user')) {
    checkRedirect();
    return (
      <Navigate
        replace
        to="/landing"
        state={{
          from: location,
        }}
      />
    );
  } else {
    return children;
  }
};

export default PrivateRoute;
