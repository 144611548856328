import React from 'react';

const BriefcaseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="20"
    fill="none"
    viewBox="0 0 22 20"
  >
    <path
      stroke="#019386"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15 19V3a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v16M3 5h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2"
    ></path>
  </svg>
);

export default BriefcaseIcon;
